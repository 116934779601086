import { isEqual as _isEqual, pick as _pick } from 'lodash'

import {
  getFullCountryNameByCountryCode,
  getRegionNameByCountryAndRegionCode,
} from '@/utils/regionData'

import { convertFieldstoneOrEtakaAddress, EKATA_ADDRESS_FIELDS } from '@/utils/helpers'

const EKATA_KEY = process.env.VUE_APP_EKATA_KEY

const EkataAddressValidationMixin = {
  data() {
    return {
      apiBaseUrl: 'https://proapi.whitepages.com/3.1/location_intel',
      overrideValidation: false,
      addressValidationInProgress: false,
    }
  },
  watch: {
    address: {
      handler() {
        if (this.overrideValidation) {
          this.overrideValidation = false
        }
      },
      deep: true,
    },
  },
  methods: {
    remoteAddressLookup(addressToValidate) {
      return new Promise((resolve, reject) => {
        let queryString = ''

        for (let [key, value] of Object.entries(addressToValidate))
          queryString += `&${key}=${encodeURI(value)}`

        // Ekata Remote Address Lookup...
        fetch(`${this.apiBaseUrl}?api_key=${EKATA_KEY}${queryString}`)
          .then(async (response) => {
            const data = await response.json()
            return resolve(data)
          })
          .catch(reject)
      })
    },
    async validateAddress() {
      // if validation has completely failed and the user wishes to continue, let them
      if (this.overrideValidation) return this.onContinue()

      this.addressValidationInProgress = true

      try {
        const convertedOriginalAddress = convertFieldstoneOrEtakaAddress(this.address)

        // Ping Ekata with the user submitted address
        const response = await this.remoteAddressLookup(convertedOriginalAddress)

        if (!response.is_valid) {
          this.addressValidationErrorHandler(response, this.address, convertedOriginalAddress)
        } else {
          this.validAddressHandler(convertedOriginalAddress, response)
        }
      } catch (error) {
        this.addressValidationErrorHandler(error)
      } finally {
        this.addressValidationInProgress = false
      }
    },
    addressValidationErrorHandler(error, originalAddress, convertedAddressToValidate) {
      this.$trackEvent({
        action: 'Address Validation Failure',
        properties: {
          location: 'Footer',
          color: 'Salmon',
          CTA: 'Save Address',
          category: 'Address Validation',
          type: 'Button',
          error,
          originalAddress,
          convertedAddressToValidate,
        },
      })

      this.overrideValidation = true

      this.$root.$emit('openModal', {
        modalComponent: 'InvalidAddressPromptModal',
        modalSize: 'small',
        modalComponentProps: {
          onContinue: this.onContinue,
        },
      })
    },
    validAddressHandler(originalAddress, suggestedAddress) {
      if (suggestedAddress.zip4) {
        suggestedAddress.postal_code += `-${suggestedAddress.zip4}`
      }
      // if the original and validated address are the same, save the address
      if (_isEqual(originalAddress, _pick(suggestedAddress, EKATA_ADDRESS_FIELDS))) {
        return this.onContinue()
      }
      this.$root.$emit('openModal', {
        modalComponent: 'ConfirmAddressModal',
        modalComponentProps: {
          originalAddress,
          suggestedAddress,
          saveAddress: this.saveAddressHandler,
          addressFields: [
            {
              key: 'street_line_1',
              break: true,
            },
            {
              key: 'city',
              addComma: true,
            },
            {
              key: 'state_code',
              addComma: true,
              translateUtility: {
                method: getRegionNameByCountryAndRegionCode,
                fields: ['state_code', 'country_code'],
              },
            },
            {
              key: 'postal_code',
            },
            {
              key: 'country_code',
              translateUtility: {
                method: getFullCountryNameByCountryCode,
                fields: ['country_code'],
              },
            },
          ],
        },
      })
    },
    saveAddressHandler(address) {
      const newAddressValues = convertFieldstoneOrEtakaAddress(address, false)
      // update the store values with the incoming address to edit
      for (let [key, value] of Object.entries(newAddressValues)) {
        this[key] = value
      }
      this.onContinue()
    },
  },
}

export default EkataAddressValidationMixin
